/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';

html,
body {
    height: 100%;
}

body {
    margin: 0
}

* {
    font-family: Montserrat, Roboto, "Helvetica Neue", sans-serif;
    font-optical-sizing: auto;
}

/*
    ** IMPORTANT **
    Remove global tailwind configs
*/
*,
::before,
::after {
    box-sizing: unset;
    // border-width: initial;
    // border-style: solid !important;
    // border-right: #fff !important;
    // border-color: currentColor !important;
}

.mat-mdc-notch-piece.mdc-notched-outline__notch {
    border-right: #fff;
}

// mat-form-field, mat-form-field *, mat-form-field ::before, mat-form-field ::after {
//     box-sizing: border-box;
//     border-width: initial;
//     border-style: solid;
//     border-color: currentColor; /* or appropriate color */
// }

.root-spinner {
    color: theme('colors.brand.700');
    font-size: 4em;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

table {
    width: 100%;
}

.btn-brand {
    padding: 0.8rem 2rem !important;
    border-radius: 30px;
    background-color: theme('colors.brand.700');
    color: #fff;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: theme('colors.brand.700')
    }

    &:disabled {
        opacity: 0.7;
    }
}

.faded-linear-fullname {
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 200px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
}

.btn-x-close-modal {
    @apply ml-auto mr-5 mt-5 px-3 py-1 border-2 rounded-lg border-gray-300 text-center;
}

.skeleton-shimmer {
    background: linear-gradient(-90deg, #e0e0e0 0%, #f5f5f5 50%, #e0e0e0 100%);
    background-size: 400% 400%;
    animation: shimmer 3s ease-in-out infinite;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.skeleton-gradient {
    overflow: hidden;
    position: relative;
    background: #c3c3c3;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20%;
        height: 100%;
        background-image: linear-gradient(to right, transparent, #eeeeee, transparent);
        animation: moveGradient 1s infinite;
    }
}

@keyframes moveGradient {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100vw);
    }
}